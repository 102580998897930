<!-- Finder expansible de Grupos -->

<template>
  <div class="grupos_XM" v-if="schema">      
       
      <!-- Botones Mto -->
      <div class="conflex contenedor" style="justify-content:space-between;width:140px">
        <div>
          <!-- Mto -->
          <btramto          
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="xm"
            :estado="estado"
            @onEvent="event_btra_Mto">                
          </btramto>
        </div>
      </div>

      <!-- Controles del Mto -->
      <div class="contenedor">           
        <div class="columna">
          <div class="conflex">           
            <v-text-field
              style="width:70%;font-weight: bold;"
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              :disabled="!is_edit">            
            </v-text-field>
          </div>
        </div>                                        
      </div>
    
  </div>
</template>



<script>

  import { mixinMto } from "@/mixins/mixinMto.js"; 
  import plugs from "@/common/general_plugs"; 
  const btramto = () => plugs.groute("btramto.vue", "comp");   
     
  export default {
    mixins: [mixinMto],  
    components: { btramto },
    props: {
      padre: { type:String, default: '' },    
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}}, 
      auxRecord: { type:Object, default: ()=> {}}, 
      disparo: { type:Boolean, default:false }, 
      componenteTipo: { type:String, default:'M' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'grup_M',
        stName:'stMgrupos',
        disparoFinder:false,
        
        // Pendiente: ver donde ponemos campo_relacionado
        //campo_relacionado: "prescab_id",
      };
    },  
    

    methods: {
      ini_data() { 
        console.log("DEV " + this.api + " ********************** (ini_data)");

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;  
        
        // entorno        
        this.Entorno.header.header= false;          
        this.Entorno.btra="XM";  
      },



    }
};
</script>
